<script setup lang="ts">
//import RichTextEditor from "~/components/platform/inline-editing/RichTextEditor.vue"; -> Must fix tiptap
import { useStore } from "vuex";

// SuportsDarkModeProp interface is in this file temporarily, until it is either moved to its own file or replaced by another solution (currently replaces the mixin)
interface SupportsDarkModeProp {
	dark?: boolean;
}
// none of the props were requiered in previous code (vue2), therefore optional
interface Props extends SupportsDarkModeProp {
	text?: string;
	checkmarks?: boolean;
	editableHtml?: string;
	editable?: boolean;
	size?: "2xs" | "xs" | "s" | "m" | "l" | "xl";
}

// props with default values
const props = withDefaults(defineProps<Props>(), {
	text: undefined,
	checkmarks: false,
	editableHtml: undefined,
	editable: true,
	size: "l",
	dark: false,
});

const config = useRuntimeConfig();
const store = useStore();

// Computed Properties
const showEditControls = computed(() => {
	if (config.public.isPreview && !store.state.selectTriggerElement && props.editable) {
		return (
			(props.text && props.text.replace("&gt;", "&rsaquo;").replace(/<p><\/p>/gi, "").length > 0) ||
			(props.editableHtml && props.editableHtml.length > 0)
		);
	} else {
		return false;
	}
});

const sizeClass = computed(() => {
	if (!["2xs", "xs", "s", "m", "l", "xl"].includes(props.size)) {
		return null;
	}
	return `size-${props.size}`;
});
</script>

<template>
	<div
		:class="[checkmarks && 'checkmark', dark && 'dark', sizeClass]"
		class="richtext"
	>
		<ClientOnly v-if="showEditControls">
			<RichTextEditor
				:oldValue="text"
				:path="editableHtml"
				:text="text"
			/>
		</ClientOnly>
		<div
			v-else
			v-html="text"
		></div>
	</div>
</template>

<style lang="scss" scoped>
.richtext {
	white-space: normal;
	max-width: 100%;

	&.size {
		&-2xs {
			@include font-text-2xs;
		}

		&-xs {
			@include font-text-xs;
		}

		&-s {
			@include font-text-s;
		}

		&-m {
			@include font-text-m;
		}

		&-l {
			@include font-text-l;
		}

		&-xl {
			@include font-text-xl;
		}
	}

	:deep(*:not(:last-child)) {
		margin-bottom: 20px;
	}

	:deep(a) {
		font-size: inherit;
		line-height: inherit;
		color: $color-cta-default;
		border-bottom: 1px solid;
		cursor: pointer;
		text-decoration: none;

		&:hover {
			border-bottom-style: none;
			background-color: rgba($color-cta-hover-background, 0.08);
		}
	}

	:deep(ul) {
		display: inline-block;
		text-align: left;
		padding-left: $spacing-l;

		li {
			list-style-type: none;
			position: relative;

			&::before {
				width: 8px;
				height: 8px;
				content: "";
				position: absolute;
				top: 8px;
				left: 0;
				margin-left: -$spacing-l;
				border: 1px solid $color-neutrals-600-shade;
				border-radius: 50%;
			}
		}
	}

	&.checkmark :deep(ul li::before) {
		width: 9px;
		height: 5px;
		top: 8px;
		border-width: 0 0 1px 1px;
		transform: rotate(-48deg);
		border-color: $color-neutrals-black;
		border-radius: 0;
	}

	:deep(ol) {
		display: inline-block;
		text-align: left;
		padding-left: $spacing-l;
		counter-reset: b;
		list-style: decimal;

		> li {
			color: $color-neutrals-black;
			display: block;
			padding-left: $spacing-l;

			&::before {
				content: counter(b) ". ";
				counter-increment: b;
				position: absolute;
				margin-left: -$spacing-l;
				color: $color-information-500-core;
			}
		}
	}

	&.dark {
		color: $color-neutrals-100-tint;

		:deep(ol) {
			> li {
				color: $color-neutrals-100-tint;

				&::before {
					color: $color-information-200-tint;
				}
			}
		}
	}

	&.dark :deep(a) {
		color: $color-twe-cta-default;
	}

	:deep(strong) {
		font-weight: bold;
	}

	:deep(em) {
		font-style: italic;
	}

	:deep(img) {
		max-width: 100%;
	}

	:deep(h1) {
		margin-bottom: $spacing-xl;

		@include font-title-xl;

		@include breakpoint(mobile) {
			margin-bottom: $spacing-l;
		}
	}

	:deep(h2) {
		margin-bottom: $spacing-l;

		@include font-title-m;

		@include breakpoint(mobile) {
			margin-bottom: $spacing-m;
		}
	}

	:deep(h3) {
		margin-bottom: $spacing-m;

		@include font-title-s;

		@include breakpoint(mobile) {
			margin-bottom: $spacing-s;
		}
	}
}
</style>
